.button {
  background-color: $secondary-sidebar-background;
  color: $text-primary-color;
  height: 40px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    display: flex;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #cccccc;
    color: #2e212166;
  }
}

.secondary-text-color {
  color: $tsl-red;
}

.secondary-color {
  background-color: $tsl-red;
  color: $primary-sidebar-background;
}
