.analysis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  
  .comment-about-service {
    padding: 2px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: rgb(73, 69, 79);
    margin-left: 10px;
    gap: 5px;
    margin-right: 0px;
    margin-bottom: 20px;
    .error-icon {
      width: 18px;
      align-self: start;
      margin-top: 2px;
    }
  }

  .section-title {
    margin-bottom: 25px;
  }

  .form-field-title {
    margin-bottom: 8px;
    font-size: 14px;
    color: rgb(73, 69, 79);
  }

  .checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .time-picker-container {
    display: flex;
    justify-content: space-between;
  }

  .date-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    .calander-image {
      &.date-placeholder {
        filter: invert(66%) sepia(0%) saturate(1942%) hue-rotate(183deg) brightness(70%) contrast(50%);
      }
    }

    .date-placeholder {
      color: hsl(0, 0%, 50%);
    }
  }

  .date-picker {
    p {
      font-family: 'Assistant-Regular' !important;
    }
    border: 1px solid #c5c7d0;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    height: 40px;
  }

  .react-datepicker__month-container {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

    .react-datepicker__header {
      background-color: white;
      border-bottom: none;
    }

    .react-datepicker__day--selected {
      color: black;
      border-radius: 50%;
      background-color: rgba(207, 29, 63, 0.3);
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }
  }

  .react-datepicker-popper {
    border: none;

    .react-datepicker__triangle {
      display: none;
    }
  }

  .react-datepicker__time-list-item--selected {
    font-weight: normal !important;
  }

  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    color: black !important;
    background-color: rgba(207, 29, 63, 0.3) !important;
  }

  button {
    font-family: 'Assistant-SemiBold';
    font-size: 14px;
  }
}
