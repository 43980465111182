* {
  box-sizing: border-box;
}

// STYLE SCROLL BAR SUGGESTION
//   ::-webkit-scrollbar {
//   background-color: transparent;
//   height: 2px;
//   width: 5px;
//   left: 2px;
// }
// ::-webkit-scrollbar-thumb {
//   border-right: 3px solid transparent;
//   background-clip: padding-box;
//   background: #b5b3b3;
//   border-radius: 5px;
//   &:hover {
//     background: #767575;
//   }
// }

html {
  line-height: 1.6;
  color: $clr2;
  overflow: hidden;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Assistant-Bold';
  margin: 0;
}
p {
  margin: 0;
  font-family: 'Assistant-Regular';
}
main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background-color: #05141f;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
