.tab-picker {
  display: flex;
  height: 45px;
  width: 100%;

  .algo-tab {
    cursor: pointer;
    color: rgb(117, 117, 117);
    h3 {
      font-family: 'Assistant-SemiBold';
      font-size: 14px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    &.selected {
      h3 {
        font-family: 'Assistant-Bold';
      }
      color: Black;
      border-bottom: 2px solid Black;
    }
  }
}
