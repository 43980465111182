.secondary-sidebar {
  background-color: #ffffff;
  width: 66px;
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.user-icon {
  padding: 0 0 10px 0;
  .user-image {
    border-radius: 50%;
    position: relative;
    float: left;
  }
  .user-line {
    position: relative;
    z-index: 99;
  }
  .sign-out-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 5;
    cursor: pointer;
    gap: 10px;
    height: 30px;
    width: 200px;
    position: absolute;
    margin: 5px 0 0 40px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 15px 0px 15px #dddddd;
    .sign-out-icon {
      margin-inline-start: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.secondary-sidebar-options {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.activity-icon {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.logo {
  display: flex;
  width: 45px;
  height: 60px;
  border-bottom: 1px solid rgba(#252525, 0.1);
  padding-bottom: 15px;
  margin: 15px auto auto auto;
}
