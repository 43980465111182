//colors for sidebar
$primary-sidebar-background: #fbfbfb;
$secondary-sidebar-background: #ffffff;
$tsl-red: #cf1d3f;
$text-primary-color: #252525;

// Colors
$clr1: #292f4c;
$clr8: #333;
$clr2: #323338;
$clr3: #676879;
$clr4: #6a6e82;
$clr5: #cce5ff;
$clr6: #f5f6f8;
$clr7: #fff;

//Main Colors
$primary-color: #0073ea;
$primary-hover-color: #0060b9;
$primary-selected-color: #cce5ff;
$primary-text-color: #323338;
$primary-selling-color: #2b2c5d;
$positive-color: #258750;
$negative-color: #d83a52;
$private-color: #f65f7c;
$shareable-color: #a25ddc;
$login-color: #4353ff;

//Status Colors
$color-done-green: #00c875;
$color-done-hover-green: #80e3ba;
$color-working-orange: #fdab3d;
$color-working-hover-orange: #fed59e;
$color-stuck-red: #e2445c;
$color-hover-red: #ce3048;
$color-stuck-hover-red: #f0a1ad;
$color-bubble-purple: #faa1f1;
$color-bubble-hover-purple: #fcd0f8;

//Greyscale Colors
$btn-column-color: #777;
$text-column-color: #666;
$mud-color: #323338;
$wolf-color: #c5c7d0;
$ui-grey-color: #e6e9ef;
$riverstone-color: #f5f6f8;

// Breakpoints
$mobile-breakpoint: 460px;
$narrow-breakpoint: 760px;
$normal-breakpoint: 960px;
