.aoi-name-prompt {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 45%;
  color: #49454f;

  .aoi-name-prompt-input {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 10px;
  }

  .aoi-name-prompt-btn {
    font-weight: 600;
  }
}

.esri-coordinate-conversion {
  width: 200px !important;
  .esri-coordinate-conversion__mode-toggle {
    display: none !important;
  }
  .esri-coordinate-conversion__select-row {
    display: none !important;
  }
}

.power-by {
  position: absolute;
  left: 58%;
  background-color: rgba(255, 255, 255, 0.8);
  height: 20px;
  padding: 4px;
  bottom: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.arcgis-map {
  width: 75vw;
  height: 100vh;
}
.esri-feature__content-element {
  span {
    font-family: 'Assistant-SemiBold' !important;
  }
  b {
    font-family: 'Assistant-Bold' !important;
  }
}
.esri-legend__message {
  display: none;
}

.esri-sketch__info-panel {
  display: none !important;
}

.esri-popup__footer--has-actions {
  display: none !important;
}

.esri-legend__service {
  padding: 4px !important;
}

.esri-widget__heading {
  margin: 0 0 0.2rem 0.2rem !important;
}

.esri-legend__layer-table {
  margin-bottom: 2px !important;
}
