.layers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 24px 22px 16px 24px;
  max-width: calc(25vw - 66px);
  .seperate-line {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(37, 37, 37, 0.1);
    margin-bottom: 16px;
  }

  .layers-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .layers-title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .layers-info {
      .layers-counter {
        font-size: 14px;
      }
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
    }

    .layers-info {
      :first-child {
        color: rgba(#49454f, 0.4);
      }

      .filter-label {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .filter-label-img {
          height: 10px;
        }
      }
    }
    .filters-list {
      display: flex;
      gap: 9px;
      margin-bottom: 9px;
      max-height: 100%;

      .filter-item {
        display: flex;
        width: max-content;
        gap: 5px;
        align-items: center;
        background-color: #f3f3f3;
        font-size: 11px;
        border-radius: 4px;
        padding: 3px 9px;
        img {
          cursor: pointer;
          max-width: 7px;
          max-height: 7px;
        }
      }
    }
  }
}

.interest-areas-container {
  direction: ltr;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .layers-not-found-image {
    text-align: center;
    width: 4.5vw;
  }
  .layers-not-found-text {
    text-align: center;
  }
}

.interest-areas-wrapper {
  direction: rtl;
  max-height: 100%;
  overflow-y: auto;
}
