.filter-container {
  font-family: 'Assistant-Regular';
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 1vh;
  left: 26vw;
  width: 250px;
  background-color: #fbfbfb;
  z-index: 100;
  padding: 20px;
  font-size: 14px;

  .filter-title {
    color: rgb(73, 69, 79);
    font-size: 20px;
    margin-bottom: 10px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  .exit-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    align-self: end;
  }
  .form-field-title {
    color: rgbA(73, 69, 79, 0.7);
    margin-bottom: 8px;
  }

  &.closed {
    display: none;
  }
  button {
    margin-top: 10px;
    font-size: 14px;
  }
}

.date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  img {
    height: 15px;
    width: 15px;
  }
  margin-bottom: 30px;
}

.date-picker {
  border: 1px solid #c5c7d0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2px 4px;
  height: 40px;
}

.react-datepicker__month-container {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }

  .react-datepicker__day--selected {
    color: black;
    border-radius: 50%;
    background-color: rgba(207, 29, 63, 0.3);
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
}

.react-datepicker-popper {
  border: none;

  .react-datepicker__triangle {
    display: none;
  }
}

.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  color: black !important;
  background-color: rgba(207, 29, 63, 0.3) !important;
}
