.new-aoi-container {
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 24px;

  .new-aoi-title {
    font-size: 20px;
  }

  .line {
    border-bottom: 1px solid rgb(73, 69, 79, 0.1);
  }

  .new-aoi-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  textarea {
    resize: none;
    font-size: 14px;
    border-radius: 4px;
    padding: 8px 16px;
    max-height: 40px;
    max-width: 250px;
    border: 1px solid rgb(197, 199, 208);
  }

  .exit-button {
    margin: 0;
    padding: 0;
    width: 70px;
    display: flex;
    color: black;
    font-family: 'Assistant-Regular';
    align-items: center;
    border: 0;
    background-color: transparent;
    gap: 12px;
    &:hover {
      transform: scale(1.05);
    }
  }

  .form-field-title {
    font-size: 14px;
    opacity: 70%;
  }

  .new-aoi-main-text {
    font-size: 16px;
    opacity: 56%;
  }

  .buttons-section {
    display: flex;
    button {
      font-size: 14px;
    }
  }
}
