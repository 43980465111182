.css-1pahdxg-control,
.css-1s2u09g-control {
  font-family: 'Assistant-Regular' !important;
  margin-bottom: 24px;
}
.css-319lph-ValueContainer,
.css-6j8wv5-Input,
.css-14el2xx-placeholder {
  font-size: 14px !important;
}
//style select header hover and active
.css-1pahdxg-control,
.css-1pahdxg-control:active,
.css-1s2u09g-control:hover {
  font-family: 'Assistant-Regular' !important;
  border-color: #323338 !important;
  box-shadow: none !important;
}

// remove seperator line inside select header
.css-1okebmr-indicatorSeparator {
  display: none !important;
}

//style select header
.css-1s2u09g-control {
  font-family: 'Assistant-Regular' !important;
  background-color: #fbfbfb !important;
  border-color: #c5c7d0 !important;
}

//style hover option
.css-1n7v3ny-option {
  background-color: rgba(73, 69, 79, 0.1) !important;
  border: 1px solid rgba(73, 69, 79, 0.1) !important;
}

//style selected option
.css-9gakcf-option {
  font-family: 'Assistant-Regular' !important;
  color: white !important;
  background-color: rgba(73, 69, 79, 0.4) !important;
  border: 1px solid rgba(73, 69, 79, 0.1) !important;
  &:hover {
    opacity: 0.7 !important;
  }
}

//style option that not selected or hovered
.css-yt9ioa-option {
  font-family: 'Assistant-Regular' !important;
  border: 1px solid rgba(73, 69, 79, 0.1) !important;
}

//style options dropdown
.css-26l3qy-menu {
  font-family: 'Assistant-Regular' !important;
  padding: 0 !important;
}

.css-4ljt47-MenuList {
  padding: 0 !important;
}

.css-1s2u09g-control {
  cursor: pointer !important;
}
