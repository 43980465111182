@font-face {
  font-family: Assistant-Bold;
  src: url(../fonts/Assistant-Bold.otf);
}

@font-face {
  font-family: Assistant-ExtraBold;
  src: url(../fonts/Assistant-ExtraBold.otf);
}

@font-face {
  font-family: Assistant-ExtraLight;
  src: url(../fonts/Assistant-ExtraLight.otf);
}

@font-face {
  font-family: Assistant-Light;
  src: url(../fonts/Assistant-Light.otf);
}

@font-face {
  font-family: Assistant-Regular;
  src: url(../fonts/Assistant-Regular.otf);
}

@font-face {
  font-family: Assistant-SemiBold;
  src: url(../fonts/Assistant-SemiBold.otf);
}
