.sidebar {
  width: 25vw;
  max-height: 100vh;
  display: flex;
  justify-content: flex-start;
  background-color: $primary-sidebar-background;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
}

@import '../../components/sidebarMenu/components/SecondarySidebar/_secondary-sidebar';
@import '../../components/sidebarMenu/components/SidebarContent/_sidebar-content';
@import '../../components/sidebarMenu/components/Dropdown/_dropdown.scss';
@import '../../components/sidebarMenu/components/Layers/_layers';
@import '../../components/sidebarMenu/components/Button/_button';
@import '../../components/sidebarMenu/components/AOI/_area-of-interest';
@import '../../components/sidebarMenu/components/AlgorithmLayers/_algorithm-layers';
@import '../../components/sidebarMenu/components/TabPicker/_tab-picker';
@import '../../components/sidebarMenu/components/Analysis/_analysis';
@import '../../components/sidebarMenu/components/RealTimeAlgo/_real-time-algo.scss';
@import '../../components/newAoi/_new-aoi.scss';
