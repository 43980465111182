.algorithm-layers-list {
  display: flex;
  flex-direction: column;
  direction: rtl;
  max-height: 40vh;
  overflow-y: scroll;
  list-style-type: none;
  margin: 0;
  padding: 0;

  .layer-name {
    white-space: nowrap;
    max-width: calc(25vw - 245px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .algorithm-layers-item {
    text-align: start;
    direction: ltr;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 20px;
    gap: 10px;
    .visiability-button {
      img {
        height: 14px;
        width: 20px;
        max-height: 14px;
      }
      display: flex;
      align-self: center;
      justify-content: center;
      align-content: center;
    }

    .visiable-button {
      display: none;
      background: transparent;
      box-shadow: 0px 0px 0px transparent;
      border: 0px solid transparent;
      text-shadow: 0px 0px 0px transparent;
    }
    .unvisiable-button {
      display: flex;
      cursor: pointer;
      background: transparent;
      box-shadow: 0px 0px 0px transparent;
      border: 0px solid transparent;
      text-shadow: 0px 0px 0px transparent;
    }

    &.visiable {
      .visiable-button {
        display: flex;
        cursor: pointer;
      }
      .unvisiable-button {
        display: none;
      }
    }
    .more-actions-icon {
      display: none;
    }

    .more-actions {
      display: flex;
      position: absolute;
      left: calc(calc(25vw - 104px) - 38px);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
      flex-direction: column;
      z-index: 9999;
      &.hidden {
        display: none;
      }
      .action {
        img {
          height: 15px;
        }
        &:hover {
          background-color: rgb(225, 225, 228);
        }
        border-radius: 4px;
        cursor: pointer;
        align-items: center;
        background-color: white;
        gap: 10px;
        display: flex;
        flex-direction: row;
        padding-inline-start: 10px;
        height: 50px;
        min-width: 250px;
      }
    }
    &:hover {
      .more-actions-icon {
        display: flex;
        margin-inline-start: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:hover {
          filter: brightness(0.1) sepia(1) hue-rotate(180deg) saturate(5);
        }
      }
    }
  }

  .show-all {
    margin: 0;
    margin-bottom: 10px;
    margin-top: 6px;
    font-weight: 100;
    margin-left: 20px;
    .layer-name {
      color: rgba(73, 69, 79, 1);
    }
  }
}

.algorithm-name {
  display: flex;
  gap: 10px;
  margin-inline-start: 5px;
}

.algorithm-layers-title {
  direction: ltr;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.action {
  height: 100%;
  width: 250px;
  img {
    margin-inline-start: 10px;
    height: 15px;
  }
  &:hover {
    background-color: rgb(225, 225, 228);
  }
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 200px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 4px;
}

.tooltip-text::before {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  background-color: #192733;
  display: none;

  z-index: 100000;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#top {
  top: -30px;
  left: -30%;
}

#top::before {
  top: 80%;
  left: -30%;
}

#top-bio {
  top: -40px;
  left: -30%;
}
#top-bio::before {
  top: 80%;
  left: -30%;
}

.hover-text {
  position: relative;
  display: inline-block;
  font-family: Avenir;
  text-align: center;
}
