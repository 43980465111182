//login screen style
[data-amplify-container] {
  width: 1000px;
}

[data-amplify-form] {
  height: 780px;
  display: flex;
  float: left;
  border: none !important;
}

// @media (min-width: 30rem){
[data-amplify-authenticator] [data-amplify-container] {
  width: 1200px !important;
  display: flex;
}
// }

[data-amplify-router] {
  border-color: white;
  background-color: white !important;
  box-shadow: none !important;
  border-color: white !important;
  border-width: none;
  border-style: none !important;
  display: flex;
}

.logo-img {
  width: 380px;
  margin: auto;
}

.logo-container {
  width: 50%;
  display: flex;
}

[data-amplify-footer] {
  height: 0px !important;
}

[data-amplify-footer] > .amplify-button {
  background-color: white !important;
  color: #b0b0b0;
  margin-top: 590px !important;
  width: 200px !important;
}

.amplify-flex {
  width: 370px !important;
}

fieldset {
  margin: auto !important;
}

.amplify-button--primary {
  background-color: #cf1d3f !important;
  color: white !important;
  font-weight: bold !important;
}

.amplify-button--small {
  color: rgb(131, 140, 149) !important;
  font-weight: none !important;
}

[data-amplify-authenticator-signin] {
  width: 50%;

  div {
    &.amplify-flex {
      width: 370px !important;
      margin: auto !important;
    }
  }

  .amplify-label {
    font-size: 32px !important;
    font-weight: bold !important;
  }

  .amplify-button,
  .amplify-field__show-password {
    background-color: #cf1d3f !important;
    margin-top: 50px !important;
  }

  .amplify-field-group__outer-end .amplify-field-group__control {
    background-color: white !important;
    border: none;
    margin-top: 0px !important;
  }

  .amplify-field-group {
    background: #ffffff;
    border: 0.8px solid #282828 !important;
    border-radius: 6px !important;
  }

  .amplify-input {
    border-style: none !important;
    border: none !important;
  }
}

.amplify-select__icon-wrapper {
  .amplify-icon {
    margin-left: 350px !important;
  }
}

.sign-out-btn {
  border: 1px solid red;
  background-image: url('../../../public/icons/sign-out.png');
  width: 30px;
}

.amplify-heading--3 {
  margin-bottom: 150px !important;
}

.wise-step {
  display: flex;
  position: absolute;
  top: 15px;
}

.step {
  display: flex;
  flex-direction: column;
}

.step-img {
  margin: auto;
  width: 40px;
  height: 40px;
}

.step-label {
  font-size: 11px;
}

.auth-button {
  margin-top: 14px;
  background-color: #cf1d3f !important;
  color: white !important;
  border: none !important;
  font-weight: bold;
  border-radius: 0.25rem;
  height: 42px;
  width: 370px;
  padding: 8px 16px 8px 16px;
  font-size: 16px;
  margin: auto;
  &:disabled {
    cursor: not-allowed;
    background-color: #cccccc !important;
    color: #2e212166 !important;
  }
}

.amplify-button--primary {
  text-align: center;
  &:disabled {
    cursor: not-allowed;
    background-color: #cccccc !important;
    color: #2e212166 !important;
  }
}

select option[value=''] {
  color: #999999;
}

.terms-button {
  position: absolute;
  font-size: 16px;
  bottom: 5vh;
  left: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
  .clickable {
    cursor: pointer;
    color: rgba(207, 29, 63, 0.8);
  }
}

.error-message,
.amplify-text--error {
  background-color: rgb(245, 188, 188) !important;
  color: rgb(102, 0, 0) !important;
}

.amplify-radio__button {
  width: 15px !important;
}

.amplify-radio {
  width: 215px !important;
}

.amplify-heading--3 {
  margin-bottom: 0px !important;
}

h3 {
  font-size: 32px;
  font-weight: bold;
}
