.area-of-interest {
  direction: ltr;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 10px 16px;
  background-color: white;

  h3 {
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(25vw - 215px);
  }

  .title-inner-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .visiability-button {
    img {
      height: 14px;
      width: 20px;
      max-height: 14px;
    }
    display: flex;
    align-self: center;
    justify-content: center;
    align-content: center;
  }

  .visiable-button {
    margin: 0;
    padding: 0;
    display: none;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  }
  .unvisiable-button {
    margin: 0;
    padding: 0;
    display: flex;
    cursor: pointer;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  }

  &.visiable {
    .visiable-button {
      display: flex;
      cursor: pointer;
    }
    .unvisiable-button {
      display: none;
    }
  }

  .fire-active-img {
    display: none;
  }

  .more-actions-icon {
    display: none;
  }

  .download {
    height: 15px;
    width: 15px;
  }

  &.fire-active {
    .fire-active-img {
      display: block;
    }
    box-shadow: 1px 2px 2px 1px rgba(231, 49, 49, 0.276);
    border: 1px solid $tsl-red;
  }

  .area-of-interest-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icons-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }

  .collapsiable-arrow {
    width: 12px;
    height: 12px;
  }

  .open-arrow {
    cursor: pointer;
    &.opened {
      filter: brightness(0%);
    }
  }
  &.open {
    .area-of-interest-title {
      border-bottom: 1px solid rgba(73, 69, 79, 0.1);
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  &:hover {
    .more-actions-icon {
      display: flex;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:hover {
        filter: brightness(0.1) sepia(1) hue-rotate(180deg) saturate(5);
      }
    }
  }
}

.action {
  height: 100%;
  width: 250px;
  img {
    margin-inline-start: 10px;
    height: 15px;
  }
  &:hover {
    background-color: rgb(225, 225, 228);
  }
}
