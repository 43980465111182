.real-time-algorithm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 20px 20px 20px 20px;
  p {
    font-size: 14px;
    margin-bottom: 8px;
  }

  button {
    font-family: 'Assistant-SemiBold';
    font-size: 14px;
  }
}
