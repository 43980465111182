.terms-modal-content {
  border-radius: 8px;
  width: 100%;
  max-width: 1000px !important;
}
.conditions-popup {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 49%;
  .content {
    margin: 20px;
    height: 80vh;
    overflow: auto;
  }
  h2 {
    margin-bottom: 8px;
  }
  p {
    margin-inline: 5px;
    margin-bottom: 10px;
    color: #717171;
    text-align: center;
    font-size: 16px;
  }
  .button {
    width: 100px;
    margin-bottom: 20px;
  }
  .warning-sign {
    max-width: 65px;
    max-height: 65px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
  .exit-icon {
    cursor: pointer;
    margin: 10px;
    width: 12px;
    height: 12px;
    align-self: end;
  }
}
