.confirm-popup {
  border-radius: 8px;
  max-width: 300px !important;
  height: 200px;
}
.confirmation-popup {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 49%;
  h2 {
    margin-bottom: 8px;
  }
  p {
    margin-inline: 50px;
    margin-bottom: 30px;
    color: #717171;
    text-align: center;
    font-size: 16px;
  }
  .button {
    width: 100px;
    margin-bottom: 20px;
  }
  .warning-sign {
    max-width: 65px;
    max-height: 65px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
  .exit-icon {
    cursor: pointer;
    margin: 10px;
    width: 12px;
    height: 12px;
    align-self: end;
  }
}
